import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { COLORS } from 'COLORS'

import { Button, ButtonType } from '@junglescout/edna'

import { mountWistia } from 'services/wistia'

import { AbstractModal, Footer } from './Abstract'

const SubTitle = styled.h3`
  ${TEXT_STYLES.H3Black}
  margin: 0;
  padding: 0;
`

const VideoFrame = styled.div`
  width: 900px;
  opacity: 0.99;
  border-radius: 2px;
  background-color: ${COLORS.grey100};
  padding: 15px;
  border: 1px solid ${COLORS.grey300};
  margin-top: 20px;
  margin-bottom: 20px;
`

class TutorialStep extends AbstractModal {
  componentDidMount() {
    mountWistia()
  }

  renderVideo = video => {
    return (
      <div
        className="wistia_responsive_padding"
        style={{
          padding: '62.5% 0 0 0',
          position: 'relative',
          width: '100%',
          height: '100%'
        }}>
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%'
          }}>
          <div
            className={`wistia_embed wistia_async_${video} videoFoam=true autoPlay=true`}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      </div>
    )
  }

  handleBackButtonClick() {
    const {
      showMwsSyncModal,
      backButtonClickHandler,
      previousModal,
      isUpgrade
    } = this.props

    return backButtonClickHandler
      ? backButtonClickHandler()
      : showMwsSyncModal(previousModal, isUpgrade)
  }

  render() {
    const { t, title, className, hideSubtitle } = this.props
    const subtitle = t('mwsSync:TutorialModal.title', 'Sync your MWS account')
    const modalTitle = title || subtitle
    return (
      <div className={className} title={modalTitle}>
        {!hideSubtitle && <SubTitle>{subtitle}</SubTitle>}
        <VideoFrame>{this.renderVideo('g3n1uujdgl')}</VideoFrame>
        <Footer>
          <Button
            btnType={ButtonType.TERTIARY}
            onClick={() => this.handleBackButtonClick()}>
            {t('generic:Back', 'Back')}
          </Button>
        </Footer>
      </div>
    )
  }

  get width() {
    return undefined
  }
}

TutorialStep.propsTypes = {
  title: PropTypes.string,
  previousModal: PropTypes.string,
  isUpgrade: PropTypes.bool,
  backButtonClickHandler: PropTypes.func,
  hideSubtitle: PropTypes.bool
}

const WrappedComponent = withTranslation(['generic, mwsSync'])(TutorialStep)
export { WrappedComponent as TutorialStep }
